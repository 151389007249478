import { connect } from 'react-redux';
import { ResponsiveLayout, logout } from 'componentlibrary';
import { getCurrentLanguage, languageOptions } from '../../translations/languageOptions';
import config from '../../Config';
import { localeMapper } from '../../utils/LocaleData';
import { refreshMicroFrontend } from '../../reducers/microFrontend';

const mapStateToProps = (state, ownProps) => ({
  userEmail: state.auth.idToken.email,
  username: state.auth.idToken.preferred_username,
  jwtToken: state.auth.idTokenJwtString,
  forwoodIdUrl: config.forwoodId.URL,
  forwoodIdGateway: config.forwoodId.USER_TOKEN_URL,
  environmentName: config.ENVIRONMENT_NAME,
  getCurrentLanguage,
  languageOptions,
  localeMapper,
  appType: 'admin-container',
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  logout(...args) {
    return logout(dispatch, ...args);
  },
  refreshMicroFrontend(...args) {
    return refreshMicroFrontend(dispatch, ...args);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveLayout);
