import { combineReducers } from 'redux';

import { appConfig, auth } from 'componentlibrary';

import microFrontend from './microFrontend';

const appReducer = combineReducers({
  appConfig,
  microFrontend,
  auth,
});

export default (state, action) => appReducer(state, action);
