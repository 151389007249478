import { connect } from 'react-redux';
import { getIdToken } from 'componentlibrary';

import MicroFrontend from '../../Components/MicroFrontend';

const mapStateToProps = (state, ownProps) => ({
  microFrontend: state.microFrontend,
  auth: state.auth,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getIdToken(...args) {
    return getIdToken(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MicroFrontend);
